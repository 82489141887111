@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');

* {
    margin: 0;
    padding: 0;
    font-weight: 300;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Roboto', sans-serif;
    background: #eeeeee;
}

.container {
    display: block;
    margin: 0 auto;
    width: 100%;
    padding: 0 32px;
    max-width: 1280px;
    box-sizing: border-box;
}

::selection {
    color: white;
    background: #3867d6;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: #3867d6;
    border-radius: 3px;
}

::-webkit-scrollbar-track {
    background: #091022;
}
